<ion-buttons>
  <ion-button>
    <ion-icon slot="icon-only" name="cloud-upload-outline"></ion-icon>
  </ion-button>
  <ion-text class="small-text" color="secondary">
    {{counter ? counter.pendingUpload : 0}}
  </ion-text>

  <ion-button>
    <ion-icon slot="icon-only" name="star-outline"></ion-icon>
  </ion-button>
  <ion-text class="small-text" color="secondary">
    {{counter ? counter.starred : 0}}
  </ion-text>

  <ion-button>
    <ion-icon slot="icon-only" color="success" name="thumbs-up-outline"></ion-icon>
  </ion-button>
  <ion-text class="small-text" color="success">
    {{counter ? counter.approved : 0}}
  </ion-text>

  <ion-button>
    <ion-icon slot="icon-only" color="danger" name="thumbs-down-outline"></ion-icon>
  </ion-button>
  <ion-text class="small-text" color="danger">
    {{counter ? counter.rejected : 0}}
  </ion-text>

  <ion-button>
    <ion-icon slot="icon-only" color="warning" name="sync-outline"></ion-icon>
  </ion-button>
  <ion-text class="small-text" color="warning">
    {{counter ? counter.requestedRetake : 0}}
  </ion-text>

  <ion-button>
    <ion-icon slot="icon-only" name="stopwatch-outline"></ion-icon>
  </ion-button>
  <ion-text class="small-text" color="secondary">
    {{counter ? counter.awaiting : 0}}
  </ion-text>
</ion-buttons>