/**
 * This file contains authentication parameters. Contents of this file
 * is roughly the same across other MSAL.js libraries. These parameters
 * are used to initialize Angular and MSAL Angular configurations in
 * in app.module.ts file.
 */

import { LogLevel, Configuration, BrowserCacheLocation, PromptValue, IPublicClientApplication, InteractionType } from '@azure/msal-browser';
import { DynamicEnvironmentService } from '@sharedLibrary/services/dynamic-environment.service';
import { InjectionToken } from '@angular/core';
import { MsalInterceptorConfiguration } from '@azure/msal-angular';

export const B2C_POLICIES = new InjectionToken<any>('B2C_POLICIES');

/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */
export function MSALPolicyFactory(envService: DynamicEnvironmentService): any {
    /**
     * Enter here the user flows and custom policies for your B2C application,
     * To learn more about user flows, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
     * To learn more about custom policies, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
     */
    const b2cPolicies = {
        names: {
            customSignUpSignIn: 'B2C_1A_signup_signin',
            customResetPassword: 'B2C_1A_PasswordReset',
            editProfile: 'B2C_1_edit_profile',
            impersonateUser: 'B2C_1A_Impersonation',
            customChangePassword: 'B2C_1A_PasswordChange'
        },
        authorities: {
            customResetPassword: {
                authority: `https://${envService.environment.B2cDomain}.b2clogin.com/${envService.environment.B2cDomain}.onmicrosoft.com/B2C_1A_PasswordReset`,
            },
            editProfile: {
                authority: `https://${envService.environment.B2cDomain}.b2clogin.com/${envService.environment.B2cDomain}.onmicrosoft.com/b2c_1_edit_profile`,
            },
            impersonateUser: {
                authority: `https://${envService.environment.B2cDomain}.b2clogin.com/${envService.environment.B2cDomain}.onmicrosoft.com/B2C_1A_Impersonation`,
            },
            customChangePassword: {
                authority: `https://${envService.environment.B2cDomain}.b2clogin.com/${envService.environment.B2cDomain}.onmicrosoft.com/B2C_1A_PasswordChange`
            },
            customSignUpSignIn: {
                authority: `https://${envService.environment.B2cDomain}.b2clogin.com/${envService.environment.B2cDomain}.onmicrosoft.com/B2C_1A_signup_signin`
            }
        },
        authorityDomain: `${envService.environment.B2cDomain}.b2clogin.com`,
        tenantId: envService.environment.B2cTenantId,
    };

    return b2cPolicies;
}

export const MSAL_CONFIG = new InjectionToken<Configuration>('MSAL_CONFIG');

/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */
export function MSALConfigFactory(envService: DynamicEnvironmentService, b2cPolicies: any): Configuration {
    /**
     * Configuration object to be passed to MSAL instance on creation.
     * For a full list of MSAL.js configuration parameters, visit:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
     */

    const msalConfig: Configuration = {
        auth: {
            clientId: envService.getClientId(), // This is the ONLY mandatory field that you need to supply.
            authority: b2cPolicies.authorities.customSignUpSignIn.authority, // Defaults to "https://login.microsoftonline.com/common"
            knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
            redirectUri: envService.getRedirectUri(), // Points to window.location.origin by default. You must register this URI on Azure portal/App Registration.
            postLogoutRedirectUri: envService.getPostLogoutRedirectUri(), // Points to window.location.origin by default.,
            navigateToLoginRequestUrl: true
        },
        cache: {
            cacheLocation: envService.getOAuthCacheLocation(),
            storeAuthStateInCookie: false
        },
        system: {
            /**
             * Below you can configure MSAL.js logs. For more information, visit:
             * https://docs.microsoft.com/azure/active-directory/develop/msal-logging-js
             */
            loggerOptions: {
                loggerCallback(logLevel: LogLevel, message: string) {
                    console.log(message);
                },
                //logLevel: LogLevel.Verbose,
                logLevel: LogLevel.Error,
                piiLoggingEnabled: false
            }
        }
    }

    return msalConfig;
}

/**
 * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export function MSALInterceptorConfigFactory(envService: DynamicEnvironmentService): MsalInterceptorConfiguration {
    const protectedResources = {
        backendApi: {
            endpoint: envService.environment.Api,
            scopes: [
                `https://${envService.environment.B2cDomain}.onmicrosoft.com/${envService.environment.BackendAppIdentifier}/BackendApi.Read.All`,
                `https://${envService.environment.B2cDomain}.onmicrosoft.com/${envService.environment.BackendAppIdentifier}/BackendApi.Write.All`]
        }
    }

    const protectedResourceMap = new Map<string, Array<string>>();
    protectedResourceMap.set(protectedResources.backendApi.endpoint, protectedResources.backendApi.scopes);
    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap
    };
}

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
    scopes: [],
    prompt: PromptValue.SELECT_ACCOUNT
}

/**
 * An optional silentRequest object can be used to achieve silent SSO
 * between applications by providing a "loginHint" property (such as a username). For more, visit:
 * https://learn.microsoft.com/en-us/azure/active-directory/develop/msal-js-sso#sso-between-different-apps
 * If you do not receive the username claim in ID tokens, see also:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/FAQ.md#why-is-getaccountbyusername-returning-null-even-though-im-signed-in
 */
export const silentRequest = {
    scopes: [],
    loginHint: "example@domain.net",
    prompt: PromptValue.SELECT_ACCOUNT
};