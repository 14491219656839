
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { AuthRedirectWrapperComponent } from '@sharedLibrary/components/auth/auth-redirect-wrapper.component';

const routes: Routes = [
  {
    // Needed for handling redirect after login
    path: 'auth',
    component: AuthRedirectWrapperComponent
  },
  {
    path: '',
    redirectTo: '/app',
    pathMatch: 'full',
  },
  {
    path: 'logout',
    redirectTo: '/'
  },
  {
    path: 'signup',
    loadChildren: () =>
      import('./sign-up/sign-up.module').then(m => m.SignupModule)
  },
  {
    path: 'app',
    loadChildren: () => import('./tabs/tabs-page/tabs-page.module').then(m => m.TabsModule),
    canActivate: [
      MsalGuard
    ]
  },

];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
