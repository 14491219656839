/// <reference types="@angular/localize" />

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from 'projects/shared/src/environments/environment';
import { enableProdMode } from '@angular/core';

if (environment.Production) {
  enableProdMode();
} else if (module['hot']) { // Check if HMR is enabled
  module['hot'].accept();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.log(err));

