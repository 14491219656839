import { Injectable } from '@angular/core';
import { PAPlusOdataConfig } from '@sharedLibrary/odata';
import { ApiConfig, ODataConfigLoader } from 'angular-odata';
import { Observable, of } from 'rxjs';
import { DynamicEnvironmentService } from './dynamic-environment.service';

@Injectable()
export class EnvironmentODataConfigLoader extends ODataConfigLoader {
    constructor(private envService: DynamicEnvironmentService) {
        super();
    }

    // Must return Observable<ApiConfig[]>
    loadConfigs(): Observable<ApiConfig[]> {
        const env = this.envService.environment;
        const config = PAPlusOdataConfig;
        config.serviceRootUrl = env.Api;

        // Return an array of ApiConfig objects in an Observable
        return of([config]);
    }
}
