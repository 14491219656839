import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { MsalBroadcastService, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MsalGuardConfiguration, MsalModule, MsalInterceptor, MSAL_INTERCEPTOR_CONFIG, MsalInterceptorConfiguration, MsalGuard } from "@azure/msal-angular";
import { Configuration, IPublicClientApplication, InteractionType } from "@azure/msal-browser";
import { PAPlusPublicClientApplication } from '../../../shared/src/lib/aadb2cauth/paplus-public-client-application';
import { B2C_POLICIES, loginRequest, MSAL_CONFIG, MSALConfigFactory, MSALInterceptorConfigFactory, MSALPolicyFactory } from '../../../shared/src/lib/aadb2cauth/auth-config';
import { RequestInterceptor } from './shared/services/interceptor.service';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SharedModule } from './shared/shared.module';
import { PAPlusOdataModule } from '@odata';
import { ODataConfigLoader, ODataModule } from 'angular-odata';
import { IonicStorageModule } from '@ionic/storage-angular';
import { PROJECT_TYPE, ProjectType } from '@sharedLibrary/services/project-type';
import { DynamicEnvironmentService } from '@sharedLibrary/services/dynamic-environment.service';
import { EnvironmentODataConfigLoader } from '@sharedLibrary/services/environment-odata-config-loader.service';
import { TreeListModule } from '@progress/kendo-angular-treelist';
import { AuthRedirectWrapperComponent } from '@sharedLibrary/components/auth/auth-redirect-wrapper.component';

/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */
export function MSALInstanceFactory(config: Configuration): IPublicClientApplication {
  return new PAPlusPublicClientApplication(config);
}

/**
 * Set your default interaction type for MSALGuard here. If you have any
 * additional scopes you want the user to consent upon login, add them here as well.
 */
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: loginRequest
  };
}

export function createTranslateLoader(http: HttpClient) {
  const path = window.location.origin + '/assets/i18n/';
  return new TranslateHttpLoader(http, path, '.json');
}

@NgModule({
  declarations: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    SharedModule,
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(),
    PAPlusOdataModule,
    MsalModule,
    ODataModule.forRoot({}),
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    IonicStorageModule.forRoot({ name: 'PhotoAuditPlus' }),
    TreeListModule, // the driver is indexedDB by default 
  ],
  bootstrap: [
    AuthRedirectWrapperComponent,
    AppComponent
  ],
  providers: [
    {
      provide: PROJECT_TYPE,
      useValue: ProjectType.Portal
    },
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: B2C_POLICIES,
      useFactory: MSALPolicyFactory,
      deps: [DynamicEnvironmentService]
    },
    {
      provide: MSAL_CONFIG,
      useFactory: MSALConfigFactory,
      deps: [DynamicEnvironmentService, B2C_POLICIES]
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
      deps: [MSAL_CONFIG]
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
      deps: [DynamicEnvironmentService]
    },
    {
      provide: ODataConfigLoader,
      useClass: EnvironmentODataConfigLoader,
      deps: [DynamicEnvironmentService]
    },
    {
      provide: LOCALE_ID,
      useValue: "en-GB"
    },
    provideHttpClient(withInterceptorsFromDi()),
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ]
})
export class AppModule { }
