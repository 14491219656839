import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MainHeaderComponent } from './components/main-header/main-header.component';
import { LoginHeaderComponent } from './components/login-header/login-header.component';
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatSelectModule } from '@angular/material/select';
import { SharedLibraryModule } from '@sharedLibrary/shared-library.module';
//import { MatIconModule } from '@angular/material/icon';
//import { MatFormFieldModule } from '@angular/material/form-field';

@NgModule({
  imports: [
    MatCheckboxModule,
    MatDialogModule,
    MatDialogModule,
    //MatFormFieldModule,
    //MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    SharedLibraryModule
  ],
  declarations: [
    LoginHeaderComponent,
    MainHeaderComponent
  ],
  exports: [
    LoginHeaderComponent, 
    MainHeaderComponent,
    MatCheckboxModule,
    MatDialogModule,
    //MatFormFieldModule,
    //MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    SharedLibraryModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule { }
