import { Pipe, PipeTransform } from "@angular/core";
import { JobStatus } from '../enums/status-enums'


@Pipe({
  name: 'jobStatusPipe'
})
export class JobStatusPipe implements PipeTransform {

  transform(value: number): unknown {

    return JobStatus[value] !== undefined ? JobStatus[value] : value;
    
  }

}
