import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { CaptureEdm, JobAssetMediaEdm, JobEdm } from '@odata';
import { MediaStatus } from '@sharedLibrary/enums/status-enums';
import { UploadCaptureComponent } from '../upload-capture/upload-capture.component';
import { CapturesService } from '@sharedLibrary/services/captures.service';
import { CommonService } from '@sharedLibrary/services/common.service';
import { JobAssetMediaService } from '@sharedLibrary/services/job-asset-media.service';
import { Position } from '@capacitor/geolocation';
import { TakeCaptureComponent } from '@sharedLibrary/components/take-capture/take-capture.component';
import { Camera, CameraSource, CameraResultType, Photo } from '@capacitor/camera';
import { LocationService } from '@sharedLibrary/services/location.service';
import { ViewCaptureComponent } from '../view-capture/view-capture.component';
import { StorageService } from '@sharedLibrary/services/storage.service';
import { IAppErrorLog } from '@sharedLibrary/models/errors';
import { logError } from '@sharedLibrary/utils/error-logger';
import { BASE64_IMAGE_PREFIXx } from '@sharedLibrary/constants/general';
import { FileSystemPath } from '@sharedLibrary/enums/storage-enums';
import { DownloadManagerService } from '@sharedLibrary/services/download-manager.service';
import { firstValueFrom, Subject, takeUntil } from 'rxjs';
import { ProgressOverlayConfig } from '@sharedLibrary/models/download-manager-interfaces';

@Component({
  selector: 'lib-capture-card',
  templateUrl: './capture-card.component.html',
  styleUrls: ['./capture-card.component.scss'],
})
export class CaptureCardComponent implements OnInit, OnDestroy {

  @Input() job: JobEdm;
  @Input() jobAssetMedia: JobAssetMediaEdm;
  @Input() assetTitle: string;

  public mediaStatus: MediaStatus;
  imageSrc: string | null = null;

  public captures: CaptureEdm[] = [];
  public mediaSourcePAPlus = 0;

  private geoLocation: Position;
  private componentDestroyed$ = new Subject<void>();

  constructor(
    private capturesService: CapturesService,
    private downloadManagerService: DownloadManagerService,
    private storageService: StorageService,
    private commonService: CommonService,
    private jobAssetMediaService: JobAssetMediaService,
    private locationService: LocationService,
    private modalController: ModalController,
    private platform: Platform
  ) { }

  ngOnInit() {
    if (this.jobAssetMedia) {
      if (this.jobAssetMedia.captures) {
        
        this.jobAssetMedia.captures.forEach(async cap => {
          
          const filePath: string = `${FileSystemPath.MediaFolder}/${this.jobAssetMedia.jobAssetGuid}/${this.jobAssetMedia.jobAssetMediaGuid}/${cap.captureGuid}`;
          const isMobileDevice = this.platform.is('hybrid') && (this.platform.is('android') || this.platform.is('ios'));
          
          var base64FileData = isMobileDevice ? await this.storageService.readFileFromFileSystem(filePath) : null;
          
          if (base64FileData) {
            this.imageSrc = BASE64_IMAGE_PREFIXx + base64FileData;
            return;
          }

          try {
          
            if (isMobileDevice) {         
              const overlayConfig: ProgressOverlayConfig = {
                message: 'Downloading job captures thumbnails...',
                showPercentage: true,
                progressBarType: 'determinate'
              };
              await this.downloadManagerService.startDownloadProcess(overlayConfig);
            }
          
            const response = await firstValueFrom(
              this.capturesService.getFileByCaptureGuid(cap.captureGuid, true)
              .pipe(takeUntil(this.componentDestroyed$))
            );
            this.imageSrc = URL.createObjectURL(response);

            if (isMobileDevice) {
              await this.storageService.saveBlobToFilesystem(response, filePath);
              this.downloadManagerService.singleItemProcessed(true);
            }

          } catch (error) {

            if (isMobileDevice) {            
              this.downloadManagerService.singleItemProcessed(false);
            }
            this.handleError(this.ngOnInit.name, error, 'thrown in: if (this.jobAssetMedia.captures) -> try/catch block');
          }
        });
      }

      this.refreshToLatestStatus();
    }

    this.capturesService.captureStatusUpdated$.subscribe((jobAssetMedia: JobAssetMediaEdm) => {
      this.refreshToLatestStatus();
    });
  }

  refreshToLatestStatus() {

    if (this.jobAssetMedia.personMediaStatuses && this.jobAssetMedia.personMediaStatuses.length > 0) {
      let latestStatus = this.jobAssetMedia.personMediaStatuses.reduce((latest, current) => {
        return new Date(current.fromUtcDateTime) > new Date(latest.fromUtcDateTime) ? current : latest;
      }, this.jobAssetMedia.personMediaStatuses[0]);
      this.mediaStatus = latestStatus.mediaStatus.enumVal;
    }
  }

  selectCapture(jobAssetMedia: JobAssetMediaEdm, capture: CaptureEdm) {
   if (this.commonService.isMobile()) {
      this.viewCapture();
    }
    else {
     if (capture != null) {
        this.capturesService.updateSelectedCapture(jobAssetMedia, capture, false);
        this.jobAssetMediaService.updateSelectedJobAssetMedia(this.jobAssetMedia);

        this.refreshToLatestStatus();
      }
    }

  }

  async viewCapture() {
    const modal = await this.modalController.create({
      component: ViewCaptureComponent,
      cssClass: "upload-capture-modal",
      componentProps:
      {
        jobAssetMedia: this.jobAssetMedia
      }
    });

    modal.onWillDismiss();

    return await modal.present();
  }

  async openCamera() {
    if (await this.locationService.isLocationServiceOn()) {

      // get and the current user's latest position
      const latestPosition = await this.locationService.getCurrentUserLocation();
      if (latestPosition) {
        this.geoLocation = latestPosition;
      }

      // check if we have valid most recent user's position
      if (!this.locationService.isLocationValid(this.geoLocation)) {
        this.commonService.openSnackBar('Failed to get your recent position.', 3000, 'error-snackbar');
        return;
      }


      const takenPhoto = await Camera.getPhoto({
        source: CameraSource.Camera,
        allowEditing: false,
        quality: 70,
        resultType: CameraResultType.Base64
      }).catch((error: Error) => console.log(error.message));

      if (takenPhoto) {
        this.showTakePhotoModal(takenPhoto);
      }
    } else {
      this.commonService.openSnackBar('Please turn on the location.', 3000, 'error-snackbar');
    }
  }

  async uploadCapture(jobAssetGuid: string, jobAssetMediaGuid: string) {
    const modal = await this.modalController.create({
      component: UploadCaptureComponent,
      cssClass: "upload-capture-modal",
      componentProps:
      {
        jobGuid: this.job.jobGuid,
        jobAssetGuid: jobAssetGuid,
        jobAssetMediaGuid: jobAssetMediaGuid
      }
    });

    modal.onWillDismiss().then((res) => {
      this.commonService.openSnackBar('Capture uploaded successfully', 3000, 'success-snackbar');
    });

    return await modal.present();
  }

  async showTakePhotoModal(photo: Photo = null) {
    const modal = await this.modalController.create({
      component: TakeCaptureComponent,
      cssClass: "upload-capture-modal",
      componentProps:
      {
        job: this.job,
        jobAssetMedia: this.jobAssetMedia,
        inputPhoto: photo
      }
    });

    modal.onWillDismiss().then((res) => {
      this.commonService.openSnackBar('Capture uploaded successfully', 3000, 'success-snackbar');
    });

    return await modal.present();
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
    /*if (this.imageSrc) {
      URL.revokeObjectURL(this.imageSrc);
    }*/  
  }

  private handleError(caller: string, error: any, message?: string) {
    const errorlog: IAppErrorLog = {
      className: this.constructor.name,
      methodName: caller,
      error,
      timestamp: new Date(),
      message
    }

    logError(errorlog);
  }
}
