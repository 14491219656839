import { Component, Input, OnInit } from '@angular/core';
import { MediaStatusCount } from '@sharedLibrary/models/media-status-count';


@Component({
  selector: 'lib-status-counter',
  templateUrl: './status-counter.component.html',
  styleUrls: ['./status-counter.component.scss'],
})
export class StatusCounterComponent  implements OnInit {

  @Input() counter: MediaStatusCount = {
    uploaded: -1,
    starred: -1,
    approved: -1,
    rejected: -1,
    requestedRetake: -1,
    awaiting: -1,
    pendingUpload: -1
  };

  constructor() { }

  ngOnInit() {  }

}
