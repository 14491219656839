import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ToastMessageService {

  constructor(private toastCtrl: ToastController) { }

  async showWarning(message: string, duration?: number) {
    const toast = await this.toastCtrl.create({
      message,
      color: 'warning',
      position: 'bottom',
      duration: duration?? 3000
    });
    await toast.present();
  }

  async showMedium(message: string, duration?: number) {
    const toast = await this.toastCtrl.create({
      message,
      color: 'medium',
      position: 'bottom',
      duration: duration?? 3000
    });
    await toast.present();
  }

  async showDanger(message: string, duration?: number) {
    const toast = await this.toastCtrl.create({
      message,
      color: 'danger',
      position: 'bottom',
      duration: duration?? 3000
    });
    await toast.present();
  }

  async showSuccess(message: string, duration?: number) {
    const toast = await this.toastCtrl.create({
      message,
      color: 'success',
      position: 'bottom',
      duration: duration?? 3000
    });
    await toast.present();
  }
}
