<div class="media-list-container">
  <div *ngFor="let media of mediaList" class="media-list-container">
    <lib-capture-card [job]="job" [assetTitle]="media.description" [jobAssetMedia]="media">
    </lib-capture-card>
  </div>

  <ion-accordion-group>
    <ion-accordion *ngFor="let child of jobAsset.childAssets" toggleIcon="chevron-down-outline" toggleIconSlot="start">
      <ion-item slot="header" color="light">
        <ion-grid class="ion-no-padding ion-padding-vertical">
          <ion-row class="ion-align-items-center">
            <ion-col size="auto">
              <ion-label>{{ child.description }}</ion-label>
            </ion-col>
            <ion-col>
              <lib-status-counter class="ion-float-right" [counter]="fillAssetCounter(child)"></lib-status-counter>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-item>
      <div class="ion-padding-vertical ion-padding-start" slot="content">

        <lib-asset-media-set [jobGuid]="jobGuid" [jobAsset]="child" [job]="job"></lib-asset-media-set>

      </div>
    </ion-accordion>
  </ion-accordion-group>

</div>