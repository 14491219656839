import { EntityKey } from 'angular-odata';
import { SignalRService } from './signalr.service';
import { Subject } from 'rxjs';

// Enum for SignalR event types, created, updated, deleted
export enum SignalREventType {
    Created = 1,
    Updated = 2,
    Deleted = 4,
    All = Created | Updated | Deleted
}

export class SignalREventConfig {
    name: string;
    type: SignalREventType = SignalREventType.Created | SignalREventType.Updated | SignalREventType.Deleted;
}

export interface SignalREvent<T> {
    name: string;
    event: SignalREventType;
    item?: T;
    itemKey?: EntityKey<T>;
}

export class BaseSignalRService<T> {
    protected eventSubject: Subject<SignalREvent<T>> = new Subject<SignalREvent<T>>();
    public events$ = this.eventSubject.asObservable();
    protected connection: signalR.HubConnection;
    protected events: SignalREventConfig[] = [];

    constructor(protected signalRService: SignalRService, protected event: SignalREventConfig | SignalREventConfig[]) {
        this.initializeSignalR();
        this.registerSignalREvent(event)
    }
    
    destroy() {
        this.unregisterSignalREvents();
    }

    protected initializeSignalR(): void {
        this.connection = this.signalRService.connection;
    }

    protected registerSignalREvent(event: SignalREventConfig | SignalREventConfig[]): void {
        console.log('Registering SignalR events');
        // Add the event or events to the array
        this.events = this.events.concat(event);

        this.events.forEach((event) => {
            if (event.type & SignalREventType.Created) {
                console.log('Registering ' + event.name + 'Created');
                this.connection.on(event.name + 'Created', (item: T) => this.handleCreated(item, event.name));
            }
            if (event.type & SignalREventType.Updated) {
                console.log('Registering ' + event.name + 'Updated');
                this.connection.on(event.name + 'Updated', (item: T) => this.handleUpdated(item, event.name));
            }
            if (event.type & SignalREventType.Deleted) {
                console.log('Registering ' + event.name + 'Deleted');
                this.connection.on(event.name + 'Deleted', (itemKey: EntityKey<T>) => this.handleDeleted(itemKey, event.name));
            }
        });
    }

    protected unregisterSignalREvents(): void {
        console.log('Unregistering SignalR events');
        this.events.forEach((event) => {
            if (event.type & SignalREventType.Created) {
                console.log('Unregistering ' + event.name + 'Created');
                this.connection.off(event.name + 'Created');
            }
            if (event.type & SignalREventType.Updated) {
                console.log('Unregistering ' + event.name + 'Updated');
                this.connection.off(event.name + 'Updated');
            }
            if (event.type & SignalREventType.Deleted) {
                console.log('Unregistering ' + event.name + 'Deleted');
                this.connection.off(event.name + 'Deleted');
            }
        });
    }

    // SignalR event handlers
    protected handleCreated(item: T, name: string): void {
        console.log(`Created ${name}`, item);
        this.eventSubject.next({ name: name, event: SignalREventType.Created, item: item });
    }

    protected handleUpdated(item: T, name: string): void {
        console.log(`Updated ${name}`, item);
        this.eventSubject.next({ name: name, event: SignalREventType.Updated, item: item });
    }

    protected handleDeleted(itemKey: EntityKey<T>, name: string): void {
        console.log(`Deleted ${name}`, itemKey);
        this.eventSubject.next({ name: name, event: SignalREventType.Deleted, itemKey: itemKey });
    }
}
