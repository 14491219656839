import { combineLatest, map, Observable, Subject } from 'rxjs';
import { EntityKey, Expand, ODataEntitySetResource, ODataEntitySetService, ODataServiceFactory } from 'angular-odata';

export interface ODataServiceConfig {
    name: string;
    entityType: any;
}

export interface BaseODataSearchResponse<T> {
    entities: T[];
    total: number;
}

export class BaseODataService<T> {
    // Underlying OData service
    protected service: ODataEntitySetService<T>;
    // Multiple items subjects
    protected apiResponseSubject: Subject<BaseODataSearchResponse<T>> = new Subject<BaseODataSearchResponse<T>>();
    protected itemsSubject: Subject<T[]> = new Subject<T[]>();
    protected totalItemsSubject: Subject<number> = new Subject<number>();
    // Single request item subject
    protected itemSubject: Subject<T> = new Subject<T>();
    // Multiple request items properties
    protected currentFilter: string;
    protected currentSortcolumn: string;
    protected currentSortOrder: string;
    protected currentWithCount: boolean;
    protected currentPageNumber: number;
    protected currentPageSize: number;
    protected currentExpand: Expand<T>;
    // Single request item properties
    protected currentItemKey: EntityKey<T>;
    protected currentItemExpand: Expand<T>;

    constructor(
        protected factory: ODataServiceFactory,
        config: ODataServiceConfig
    ) {
        this.registerEntitySet(config);

        // Combine the two BehaviorSubjects:
        combineLatest([this.itemsSubject, this.totalItemsSubject])
            .pipe(
                map(([entities, total]) => ({ entities, total }))
            )
            .subscribe(response => {
                console.log('BaseODataService: combineLatest', response);
                this.apiResponseSubject.next(response);
            });
    }

    protected destroy() {
        this.itemsSubject.complete();
        this.totalItemsSubject.complete();
    }

    // Get the item as an observable
    get item(): Observable<T> {
        return this.itemSubject.asObservable();
    }

    // Get the items as an observable
    get items(): Observable<T[]> {
        return this.itemsSubject.asObservable();
    }

    // Get the total number of items as an observable
    get totalItems(): Observable<number> {
        return this.totalItemsSubject.asObservable();
    }

    // Register the entity set
    protected registerEntitySet(config: ODataServiceConfig): ODataEntitySetService<T> {
        this.service = this.factory.entitySet<T>(config.name, config.entityType);
        return this.service;
    }

    // Search the entity set
    protected setupSearch(
        filter: string,
        sortColumn: string,
        sortOrder: string = 'asc',
        pageNumber: number = 0,
        pageSize: number = 10,
        expand?: Expand<T>
    ): ODataEntitySetResource<T> {
        this.currentFilter = filter;
        this.currentSortcolumn = sortColumn;
        this.currentSortOrder = sortOrder;
        this.currentPageNumber = pageNumber;
        this.currentPageSize = pageSize;
        this.currentExpand = expand;

        // Create the resource
        let resource = this.service.entities();

        // Apply filter, sort, paging, and expand
        if (filter) {
            resource = this.applyODataFilter(resource, filter);
        }
        if (sortColumn) {
            resource = this.applyODataSort(resource, sortColumn, sortOrder);
        }
        if (pageNumber != null && pageSize != null) {
            resource = this.applyODataPaging(resource, pageNumber, pageSize);
        }
        if (expand) {
            resource = this.applyODataExpand(resource, expand);
        }

        return resource;
    }

    protected search(
        filter: string,
        sortColumn: string,
        sortOrder: string = 'asc',
        pageNumber: number = 0,
        pageSize: number = 10,
        expand?: Expand<T>
    ): Observable<T[]> {
        this.currentWithCount = false;
        const resource = this.setupSearch(filter, sortColumn, sortOrder, pageNumber, pageSize, expand);

        resource.fetch()
            .pipe(
                map(res => {
                    this.itemsSubject.next(res.entities ?? []);
                })
            ).subscribe();

        return this.itemsSubject.asObservable();
    }

    // Search with total count
    protected searchWithCount(
        filter: string,
        sortColumn: string,
        sortOrder: string = 'asc',
        pageNumber: number = 0,
        pageSize: number = 10,
        expand?: Expand<T>
    ): Observable<BaseODataSearchResponse<T>> {
        this.currentWithCount = true;
        const resource = this.setupSearch(filter, sortColumn, sortOrder, pageNumber, pageSize, expand);

        resource.fetch({ withCount: true })
            .pipe(
                map(res => {
                    this.itemsSubject.next(res.entities);
                    this.totalItemsSubject.next(res.annots.count);
                })
            ).subscribe();
        return this.apiResponseSubject.asObservable();
    }

    refreshSearch(): void {
        if (this.currentWithCount) {
            this.searchWithCount(this.currentFilter, this.currentSortcolumn, this.currentSortOrder, this.currentPageNumber, this.currentPageSize, this.currentExpand);
        } else {
            this.search(this.currentFilter, this.currentSortcolumn, this.currentSortOrder, this.currentPageNumber, this.currentPageSize, this.currentExpand);
        }
    }

    // Apply OData filter
    protected applyODataFilter(resource: ODataEntitySetResource<T>, filter: any): ODataEntitySetResource<T> {
        // Implement logic to apply OData filter to the resource
        // This method can be overridden if needed
        return resource.query(q => q.filter(filter));
    }

    // Apply OData sort
    protected applyODataSort(resource: ODataEntitySetResource<T>, sortColumn: string, sortOrder: string): ODataEntitySetResource<T> {
        // Implement logic to apply OData sort to the resource
        // This method can be overridden if needed
        return resource.query(q => q.orderBy(sortColumn + ' ' + sortOrder));
    }

    // Apply OData paging
    protected applyODataPaging(resource: ODataEntitySetResource<T>, pageNumber: number, pageSize: number): ODataEntitySetResource<T> {
        // Implement logic to apply OData paging to the resource
        // This method can be overridden if needed
        resource = resource.query(q => q.skip(pageNumber * pageSize));
        return resource.query(q => q.top(pageSize));
    }

    // Apply OData expand
    protected applyODataExpand(resource: ODataEntitySetResource<T>, expand: Expand<T>): ODataEntitySetResource<T> {
        // Implement logic to apply OData expand to the resource
        // This method can be overridden if needed
        return resource.query(q => q.expand(expand));
    }

    // Get the entity by key
    protected get(entityKey: EntityKey<T>, expand?: Expand<T>) {
        this.currentItemKey = entityKey;
        this.currentItemExpand = expand;

        // Get the entity by key
        let entityRequest = this.service.entity(entityKey);

        // Expand the entity if needed
        if (expand) {
            entityRequest = entityRequest.query(q => q.expand(expand));
        }

        // Fetch and return the entity
        entityRequest.fetch().pipe(
            map(res => this.itemSubject.next(res.entity))
        );

        return this.itemSubject.asObservable();
    }

    protected refreshGet() {
        if (this.currentItemKey) {
            this.get(this.currentItemKey, this.currentItemExpand);
        } else {
            throw new Error('No entity key provided');
        }
    }

    // Create the entity
    protected create(entity: T): Observable<T> {
        this.service.create(entity).pipe(
            map(res => this.itemSubject.next(res.entity))
        ).subscribe();

        return this.itemSubject.asObservable();
    }

    // Update the entity
    protected update(entityKey: EntityKey<T>, entity: Partial<T>) {
        return this.service.update(entityKey, entity).pipe(
            map(res => res.entity)
        );
    }

    // Expire the entity
    protected expire(entityKey: EntityKey<T>, entity: Partial<T>) {
        if ('ToDate' in entity) {
            entity['ToDate'] = new Date;
            return this.service.update(entityKey, entity).pipe(
                map(res => res.entity)
            ).subscribe();
        } else if ('ToUTCDateTime' in entity) {
            entity['ToUTCDateTime'] = new Date;
            return this.service.update(entityKey, entity).pipe(
                map(res => res.entity)
            ).subscribe();
        } else {
            throw new Error('Entity does not have a ToDate or ToUTCDateTime property');
        }
    }

    // Delete the entity
    protected delete(entityKey: EntityKey<T>, entity: Partial<T>): Observable<any> {
        if ('Deleted' in entity) {
            return this.softDelete(entityKey, entity);
        }
        else {
            return this.hardDelete(entityKey);
        }
    }

    // Hard delete the entity
    protected hardDelete(entityKey: EntityKey<T>): Observable<any> {
        return this.service.destroy(entityKey);
    }

    // Soft delete the entity
    protected softDelete(entityKey: EntityKey<T>, entity: Partial<T>): Observable<T> {
        this.setProperty(entity, 'Deleted');
        return this.service.update(entityKey, entity).pipe(
            map(res => res.entity)
        );
    }

    // Set the property of the entity
    protected setProperty(entity: Partial<T>, propertyName: string): void {
        if (propertyName in entity) {
            if (typeof entity[propertyName] === "boolean") {
                entity[propertyName] = true;
            } else if (entity[propertyName] instanceof Date || !isNaN(Date.parse(entity[propertyName]))) {
                entity[propertyName] = new Date();
            } else {
                throw new Error(`The property "${propertyName}" is neither a Date nor a boolean.`);
            }
        } else {
            throw new Error(`The property "${propertyName}" does not exist on the entity.`);
        }
    }
}
