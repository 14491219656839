import { Component, OnInit, OnDestroy } from '@angular/core';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { EventMessage, InteractionStatus } from '@azure/msal-browser';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-auth-redirect-wrapper',
    template: ''
})
export class AuthRedirectWrapperComponent implements OnInit, OnDestroy {
    private readonly destroying$ = new Subject<void>();

    constructor(
        private msalAuthService: MsalService,
        private msalBroadcastService: MsalBroadcastService,
        private router: Router
    ) { }

    ngOnInit(): void {
        this.msalAuthService.getLogger().verbose("AuthRedirectWrapperComponent activated");

        // TODO: THis needs more work, parking for the moment!
        // this.msalBroadcastService.inProgress$
        //     .pipe(
        //         filter((status: InteractionStatus) => status === InteractionStatus.None),
        //         takeUntil(this.destroying$)
        //     )
        //     .subscribe((status: InteractionStatus) => {
        //         console.log('MSAL Broadcast inProgress$ event', status);
        //         this.redirectIfNecessary();
        //     });

        // Listen for the "redirect handling" to finish
        // this.msalBroadcastService.msalSubject$
        //     .pipe(takeUntil(this.destroying$))
        //     .subscribe((result: EventMessage) => {
        //         console.log('MSAL Subject Received event', result);
        //         this.redirectIfNecessary();
        //     });

        this.msalAuthService.handleRedirectObservable().subscribe();
    }

    ngOnDestroy(): void {
        console.log('Destroying AuthRedirectWrapperComponent');
        this.destroying$.next();
        this.destroying$.complete();
    }

    redirectIfNecessary(): void {
        // const account = this.msalAuthService.instance.getActiveAccount()
        //     || this.msalAuthService.instance.getAllAccounts()[0];

        if (location.pathname === '/auth') {
            debugger;
            console.log('Redirecting to home page');
            // TODO: This might be different for mobile and portal
            // Send them to a default route if no other route is stored
            this.router.navigate(['/app'], { skipLocationChange: true }); // or any fallback of your choice
        }
    }
}
