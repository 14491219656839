/*
*** Secure Storage Keys Enum ****
*/
export enum SecureStorageKey {
    AccessToken = 'access_token',
    RefreshToken = 'refresh_token'
}


/*
*** Local Storage Keys Enum ****
*/
export enum StorageKey {
    JobMedia = 'job-media_', // this is a prefix for a job media i.e. job_media-abc123 where abc123 is a job id/guid
    JobsData = 'jobs-data',
    JobsReferences = 'jobs-references',
    MyLocations = 'MyLocations',
    Settings = 'settings',
    SiteUrl = 'site-url',
    ThumbnailFailedDownloads = 'thumbnail-failed-downloads'
}


/*
*** File System Paths Enum ****
*/
export enum FileSystemPath {
    MediaFolder = 'media'
}
