import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { BASE64_IMAGE_PREFIXx } from '@sharedLibrary/constants/general';
import { MediaStatus } from '@sharedLibrary/enums/status-enums';
import { FileSystemPath } from '@sharedLibrary/enums/storage-enums';
import { ProgressOverlayConfig } from '@sharedLibrary/models/download-manager-interfaces';
import { CaptureEdm, JobAssetMediaEdm } from '@sharedLibrary/odata';
import { CapturesService } from '@sharedLibrary/services/captures.service';
import { CommonService } from '@sharedLibrary/services/common.service';
import { DownloadManagerService } from '@sharedLibrary/services/download-manager.service';
import { StorageService } from '@sharedLibrary/services/storage.service';
import { firstValueFrom, Subject, takeUntil } from 'rxjs';
import { SwiperOptions } from 'swiper/types';

@Component({
  selector: 'lib-view-capture',
  templateUrl: './view-capture.component.html',
  styleUrls: ['./view-capture.component.scss'],
})
export class ViewCaptureComponent implements OnInit {

  @ViewChild('swiper',) swiper: ElementRef | undefined;
  @Input() jobAssetMedia: JobAssetMediaEdm;

  public currentIndex: number = 0;
  public captureCount: number = 0;

  public mediaStatus: MediaStatus;
  public capture: CaptureEdm;
  public imageSrc = '/assets/img/image-awaiting.png';

  swiperConfig: SwiperOptions = {
    pagination: false
  };


  private componentDestroyed$ = new Subject<void>();

  constructor(
    private capturesService: CapturesService,
    private commonService: CommonService,
    private downloadManagerService: DownloadManagerService,
    private modalCtrl: ModalController,
    private platform: Platform,
    private storageService: StorageService,
  ) { }

  ngOnInit() {
    this.captureCount = this.jobAssetMedia.captures.length;
    this.currentIndex = 1;

    this.jobAssetMedia.captures.forEach(cap => {
      this.downloadCaptures(cap);
    });
  }

  async downloadCaptures(cap: CaptureEdm) {
    const filePath: string = `${FileSystemPath.MediaFolder}/${this.jobAssetMedia.jobAssetGuid}/${this.jobAssetMedia.jobAssetMediaGuid}/${cap.captureGuid}`;
    const isMobileDevice = this.platform.is('hybrid') && (this.platform.is('android') || this.platform.is('ios'));

    var base64FileData = isMobileDevice ? await this.storageService.readFileFromFileSystem(filePath) : null;

    if (base64FileData) {
      this.imageSrc = BASE64_IMAGE_PREFIXx + base64FileData;
      return;
    }
    else {
      try {

        if (isMobileDevice) {
          const overlayConfig: ProgressOverlayConfig = {
            message: 'Downloading job captures thumbnails...',
            showPercentage: true,
            progressBarType: 'determinate'
          };
          await this.downloadManagerService.startDownloadProcess(overlayConfig);
        }

        const response = await firstValueFrom(
          this.capturesService.getFileByCaptureGuid(cap.captureGuid, true)
            .pipe(takeUntil(this.componentDestroyed$))
        );

        this.imageSrc = URL.createObjectURL(response);

        //if (isMobileDevice) {
          await this.storageService.saveBlobToFilesystem(response, filePath);
          this.downloadManagerService.singleItemProcessed(true);
        //}

      } catch (error) {

        if (isMobileDevice) {
          this.downloadManagerService.singleItemProcessed(false);
        }
        this.commonService.handleError(this.ngOnInit.name, error, 'thrown in: if (this.jobAssetMedia.captures) -> try/catch block');
      }
    }


  }

  async closeModal() {
    //when a new slide is added swiper needs to be updated.
    //this.swiper.nativeElement.swiper.update();
    await this.modalCtrl.dismiss();
  }

  getStatusIcon(): string {
    let iconStr = 'cloud-upload';
    let currentStatus = this.jobAssetMedia.personMediaStatuses.find(s => s.toUtcDateTime == null);

    if (currentStatus) {
      switch (currentStatus.mediaStatus.enumVal) {
        case MediaStatus.Awaiting:
          iconStr = 'stopwatch';
          break;
        case MediaStatus.Retake:
          iconStr = 'sync';
          break;
        case MediaStatus.Rejected:
          iconStr = 'thumbs-down';
          break;
        case MediaStatus.Approved:
          iconStr = 'thumbs-up';
          break;
      }
    }

    return iconStr;
  }

  getIconColour(): string {
    let colourStr = '';
    let currentStatus = this.jobAssetMedia.personMediaStatuses.find(s => s.toUtcDateTime == null);

    if (currentStatus) {
      switch (currentStatus.mediaStatus.enumVal) {
        case MediaStatus.Awaiting:
          colourStr = '';
          break;
        case MediaStatus.Retake:
          colourStr = 'warning';
          break;
        case MediaStatus.Rejected:
          colourStr = 'danger';
          break;
        case MediaStatus.Approved:
          colourStr = 'success';
          break;
      }
    }

    return colourStr;
  }

  //COMMENTS 
  presentAddNoteModal() {

  }
  //SLIDE CONTROLS
  nextSlide() {

  }

  prevSlide() {

  }

}
