import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IAppErrorLog } from '@sharedLibrary/models/errors';
import { logError } from '@sharedLibrary/utils/error-logger';
import { Subject, takeUntil } from 'rxjs';
import { DownloadManagerService } from '@sharedLibrary/services/download-manager.service';

@Component({
  selector: 'app-downloading-overlay',
  templateUrl: './downloading-overlay.component.html',
  styleUrls: ['./downloading-overlay.component.scss'],
})
export class DownloadingOverlayComponent  implements OnInit, OnDestroy {

  @Input() message: string;
  @Input() showPercentage: boolean;
  @Input() progressBarType: 'determinate' | 'indeterminate';

  progress: number = 0;
  percentage: number = 0;

  showFailedDownloads: boolean = false;
  failedDownloads: number = 0;

  private componentDestroyed$ = new Subject<void>();

  constructor(
    private downloadManagerService: DownloadManagerService
  ) { }

  ngOnInit() {
    this.downloadManagerService.downloadProgress$
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe({
      next: (value) => {
        this.progress = value;
        this.percentage = Math.round(value * 100);
      },
      error: (error: Error) => {
        this.handleError(this.ngOnInit.name, error, 'this.downloadManagerService.downloadProgress$ subscription');
      }
    });

    this.downloadManagerService.downloadSuccessful$
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe({
      next: (itemDownloadSuccessful) => {
        if (!itemDownloadSuccessful) {
          // show the failed downloads info container and update the count
          this.showFailedDownloads = true;  
          this.failedDownloads ++;          
        }

      },
      error: (error: Error) => {
        this.handleError(this.ngOnInit.name, error, 'this.downloadManagerService.downloadSuccessful$ subscription');
      }
    });
  }
  
  ngOnDestroy(): void {
    this.downloadManagerService.resetProgress();
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  private handleError (caller: string, error: any, message?: string) {
    const errorlog: IAppErrorLog = {
      className: this.constructor.name,
      methodName: caller,
      error,
      timestamp: new Date(),
      message
    }
  
    logError(errorlog);
  }
}
