<div *ngIf="jobAssetMedia.captures.length > 0" class="media-card">

  <!-- there is no need to show all captures, only the first one -->
  <div class="media-info">
    <span class="media-title">{{ assetTitle }}</span>
    <ion-icon *ngIf="mediaStatus == 3" slot="icon-only" color="success" class="status-icon" name="thumbs-up"></ion-icon>
    <ion-icon *ngIf="mediaStatus == 2" slot="icon-only" color="danger" class="status-icon"  name="thumbs-down"></ion-icon>
    <ion-icon *ngIf="mediaStatus == 1" slot="icon-only" color="warning" class="status-icon" name="sync"></ion-icon>
    <ion-icon *ngIf="mediaStatus == 0" slot="icon-only" color="warning" class="status-icon" name="time"></ion-icon>

  </div>
  <div class="asset-container">
    <div class="media-thumbnail-container">
      <ion-img [ngClass]="jobAssetMedia.captures.length > 1 ? 'capture-series': ''" class="thumbnail" [src]="imageSrc"
        alt="assetTitle" (click)="selectCapture(jobAssetMedia, jobAssetMedia.captures[0])"></ion-img>
      <ion-img *ngIf="jobAssetMedia.captures[0].mediaSource == mediaSourcePAPlus" src="/assets/img/global_logo_org.png"
        class="media-source-icon"></ion-img>
      <ion-img *ngIf="jobAssetMedia.captures[0].mediaSource != mediaSourcePAPlus" src="/assets/img/PAPlusLogo.png"
        class="media-source-icon source-paplus"></ion-img>
    </div>
    <div class="media-info">
      <div class="asset-buttons-container">
        <ion-button (click)="openCamera()" class="camera-btn" color="secondary">
          <ion-icon name="camera-outline" color="fadeblue" class="btn-icon"></ion-icon>
        </ion-button>
        <ion-button (click)="uploadCapture(jobAssetMedia.jobAssetGuid, jobAssetMedia.jobAssetMediaGuid)"
          class="upload-media-mobile-btn" color="secondary">
          <ion-icon name="cloud-upload-outline" color="fadeblue" class="btn-icon"></ion-icon>
        </ion-button>
        <ion-button (click)="uploadCapture(jobAssetMedia.jobAssetGuid, jobAssetMedia.jobAssetMediaGuid)"
          class="upload-media-btn" color="secondary">Upload Media
        </ion-button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="jobAssetMedia.captures.length == 0" class="media-card">
  <div class="media-info">
    <span class="media-title">{{ assetTitle }}</span>
  </div>
  <div class="asset-container">
    <div class="no-image" (click)="selectCapture(jobAssetMedia, null)">
      <ion-icon name="stopwatch-outline" color="secondary" class="no-image-icon"></ion-icon>
      <span class="bolder-text">Awaiting Media</span>
    </div>
    <div class="asset-buttons-container">
      <ion-button (click)="openCamera()" class="camera-btn" color="secondary">
        <ion-icon name="camera-outline" color="fadeblue" class="btn-icon"></ion-icon>
      </ion-button>
      <ion-button (click)="uploadCapture(jobAssetMedia.jobAssetGuid, jobAssetMedia.jobAssetMediaGuid)"
        class="upload-media-mobile-btn" color="secondary">
        <ion-icon name="cloud-upload-outline" color="fadeblue" class="btn-icon"></ion-icon>
      </ion-button>
      <ion-button (click)="uploadCapture(jobAssetMedia.jobAssetGuid, jobAssetMedia.jobAssetMediaGuid)"
        class="upload-media-btn" color="secondary">Upload Media
      </ion-button>
    </div>
  </div>
</div>