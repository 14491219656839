import { Component, Input, OnInit } from '@angular/core';
import { JobAssetEdm, JobEdm, JobTemplateEdm } from '@sharedLibrary/odata';
import { AssetCounterService } from '@sharedLibrary/services/asset-counter.service';

@Component({
  selector: 'lib-job-assets-container',
  templateUrl: './job-assets-container.component.html',
  styleUrls: ['./job-assets-container.component.scss'],
})
export class JobAssetsContainerComponent  implements OnInit {

  
    @Input() job: JobEdm;
  
    public jobTemplate: JobTemplateEdm;
    public jobAssets: JobAssetEdm[] = [];
  
    constructor(
      private assetCounterService: AssetCounterService
    ) { }
  
    ngOnInit() {
      if (this.job && this.job.jobTemplates.length > 0) {
  
        this.jobTemplate = this.job.jobTemplates[0];
        this.jobTemplate.jobAssetTemplates.forEach(jat => {
          if (jat.jobAsset.jobParentAssetGuid == null) {
            this.jobAssets.push(jat.jobAsset);
          }
        });
      }
    }

  fillAssetCounter(asset: JobAssetEdm) {
    return this.assetCounterService.fillAssetCounter(asset);
  }

}
