<ion-header>

  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button size="small" class="close-modal-button toolbar-button" (click)="closeModal()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>View Capture</ion-title>
  </ion-toolbar>
</ion-header>


<ion-content>

  <ion-item-group style="display: block;">    
    <ion-item lines="none">
      <ion-label>{{ jobAssetMedia.description }}</ion-label>
      <ion-icon slot="end" class="status-icon" [color]="getIconColour()" [name]="getStatusIcon()"></ion-icon>
    </ion-item>
    <ion-item *ngIf="jobAssetMedia.captures.length > 0">
      <ion-label>{{ (capture ? (capture.createUtcDateTime | date: 'dd MMM yyyy') : '') }}</ion-label>
      <ion-text class="capture-count-text" text-right>{{ currentIndex }}/{{ captureCount }}</ion-text>
    </ion-item>

    <ion-img *ngIf="jobAssetMedia.captures.length > 0" [src]="imageSrc"></ion-img>

    <div *ngIf="jobAssetMedia.captures.length == 0" class="no-image">
      <ion-icon name="stopwatch-outline" color="secondary" class="no-image-icon"></ion-icon>
      <span class="bolder-text">Awaiting Media</span>
    </div>
    

  </ion-item-group>

  <div style="height: 100%; overflow-y: scroll; display: block;">
    <ion-list>
      <ion-item>
        <ion-label>
          <p class="header-text">Comments</p>
        </ion-label>
      </ion-item>


      <!-- <ion-item *ngFor="let note of fullSizeElement.notes" lines="none">
        <ion-label>

          <ion-label text-wrap> {{ note.text }} </ion-label>
          <h6 text-wrap><span class="small-header-text">Author:</span> {{ note.author }}</h6>
          <h6 text-wrap><span class="small-header-text">Date:</span>{{ note.date | date: 'M/d/y h:mm a' }}</h6>
        </ion-label>
      </ion-item> -->
      <!-- *ngIf="!fullSizeElement.notes || fullSizeElement.notes.length === 0" -->
      <ion-item  lines="none">
        <ion-label text-wrap>
          <h2>Capture has no notes added yet.</h2>
        </ion-label>
      </ion-item>
    </ion-list>
  </div>

</ion-content>

<ion-footer>
  <ion-row>
    <ion-col size="3">
      <ion-button (click)="prevSlide()">
        <ion-icon slot="icon-only" name="chevron-back-circle-outline"></ion-icon>
      </ion-button>
    </ion-col>
    <ion-col size="6">
      <ion-row>

        <ion-button style="width:30%" (click)="presentAddNoteModal()">
          <ion-icon slot="icon-only" name="chatbox-outline"></ion-icon>
        </ion-button>

      </ion-row>

    </ion-col>

    <ion-col size="3">
      <ion-button (click)="nextSlide()">
        <ion-icon slot="icon-only" name="chevron-forward-circle-outline"></ion-icon>
      </ion-button>
    </ion-col>

  </ion-row>
</ion-footer>
