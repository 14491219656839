import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';

import { Filesystem, Directory, StatResult } from '@capacitor/filesystem';
import { FileService } from './file.service';
import { StorageKey } from '@sharedLibrary/enums/storage-enums';
import { logError } from '../utils/error-logger';
import { IAppErrorLog } from '../models/errors';


@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private _storage: Storage | null = null;

  constructor(
    private storage: Storage,
    private fileService: FileService
  ) {
    this.init();
  }

  /**
   * Retrieves the value for a given key from the storage.
   * @param key The key to retrieve the value for.
   * @returns A promise that resolves to the value of the key, or undefined if an error occurs.
   */
  async getStorageKey(key: StorageKey): Promise<any | undefined> {
    const result = await this.storage.get(key)
    .catch(error => {
      this.handleStorageError(this.getStorageKey.name, error, `key: '${key}'`);
      return undefined;
    });
    
    return result;
  }

  /**
   * Sets a value for a given key in the storage.
   * @param key The key to set the value for.
   * @param data The value to set.
   * @returns A promise that resolves to the newly set value, or undefined if an error occurs.
   */
  async setStorageKey(key: StorageKey, data: any): Promise<any | undefined> {
    return this.storage.set(key, data).catch(error => {
      this.handleStorageError(this.setStorageKey.name, error, `key: '${key}'`);
      return undefined;
    });
  }

  /**
   * Removes the value for a given key from the storage.
   * @param key The key to remove the value for.
   * @returns A promise that resolves when the key is removed, or undefined if an error occurs.
   */
  async removeStorageKey(key: StorageKey): Promise<void | undefined> {
    return this.storage.remove(key).catch(error => {
      this.handleStorageError(this.removeStorageKey.name, error, `key: '${key}'`);
      return undefined;
    });
  }

  /**
   * Retrieves the list of files from a specified directory.
   * @param folderPath The path to the directory.
   * @returns A promise that resolves to an array of filenames, or undefined if an error occurs.
   */
  async getFolderItems(folderPath: string): Promise<string[] | undefined> {
    return Filesystem.readdir({
      directory: Directory.Data,
      path: folderPath,
    }).then(result => result.files).catch(error => {
      this.handleStorageError(this.getFolderItems.name, error, `folderPath: '${folderPath}'`);
      return undefined;
    });
  }

  /**
   * Creates a directory at the specified path if it does not already exist.
   * @param path The path where the directory should be created.
   * @returns A promise that resolves to an object indicating whether the directory was created,
   *          already exists, or an error occurred.
   */
  async createDirectoryInFilesystem(path: string): Promise<{ success: boolean, created: boolean}> {
    try {
      // check if the directory exist first
      const stat = await this.checkDirectoryExists(path);

      if (stat) {
        this.handleStorageError(this.createDirectoryInFilesystem.name, new Error('Directory already exists'), `path: '${path}'`);
        return { success: true, created: false }; // Directory already exists
      }
      
      await Filesystem.mkdir({
        path,
        directory: Directory.Data,
        recursive: true
      });

      return { success: true, created: true }; // Directory created successfully
    } catch (error) {
      this.handleStorageError(this.createDirectoryInFilesystem.name, error, `path: '${path}'`);
      return { success: false, created: false }; // There was an error
    }
  }


  /**
   * Reads the contents of a file from the filesystem.
   * @param filePath The path to the file to read.
   * @returns A promise that resolves to the contents of the file, or undefined if an error occurs.
   */
  async readFileFromFileSystem(filePath: string): Promise<string | undefined> {
    return Filesystem.readFile({
      path: filePath,
      directory: Directory.Data
    }).then(result => result.data).catch(error => {
      this.handleStorageError(this.readFileFromFileSystem.name, error, `filePath: '${filePath}'`);
      return undefined;
    });
  }

  /**
   * Saves a blob to the filesystem at the specified path.
   * @param blob The blob to save.
   * @param path The path where the blob should be saved.
   * @returns A promise that resolves to true if the file was saved successfully, or undefined if an error occurs.
   */
  async saveBlobToFilesystem(blob: Blob, path: string): Promise<boolean | undefined> {
    return this.fileService.convertFileToBase64(blob).then(async fileAsString => {
      const fileData = fileAsString.substring(fileAsString.indexOf(',') + 1);
      return this.writeToFileSystem(fileData, path).then(() => true);
    }).catch(error => {
      this.handleStorageError(this.saveBlobToFilesystem.name, error, `path: '${path}'`);
      return undefined;
    });
  }

  /**
   * Writes data to a file at the specified path.
   * @param file The data to write.
   * @param path The path where the file should be written.
   * @returns A promise that resolves to true if the file was written successfully, or undefined if an error occurs.
   */
  async writeToFileSystem(file: string, path: string): Promise<boolean | undefined> {
    return Filesystem.writeFile({
      path,
      directory: Directory.Data,
      data: file,
      recursive: true
    }).then(() => true).catch(error => {
      this.handleStorageError(this.writeToFileSystem.name, error, `path: '${path}'`);
      return undefined;
    });
  }

  /**
   * Checks if a directory exists at the specified path.
   * @param path The path where the directory is expected to be located.
   * @returns A promise that resolves to an object indicating whether the directory exists
   *          and any error information if an error occurs during the check.
   */
  async checkDirectoryExists(path: string): Promise<StatResult | undefined> {
    return Filesystem.stat({
        path: path,
        directory: Directory.Data
      })     
    .catch((error) =>{
      this.handleStorageError(this.checkDirectoryExists.name, error, `path: '${path}'`);
      return undefined;
    });
  }

  /**
   * Deletes a directory at the specified path.
   * @param folderPath The path of the directory to delete.
   * @param recursive Whether to delete the contents recursively.
   * @returns A promise that resolves to true if the directory was deleted successfully, or undefined if an error occurs.
   */
  async removeFolderFromFilesystem(folderPath: string, recursive = true): Promise<boolean | undefined> {
    return Filesystem.rmdir({
      path: folderPath,
      directory: Directory.Data,
      recursive
    }).then(() => true).catch(error => {
      this.handleStorageError(this.removeFolderFromFilesystem.name, error, `folderPath: '${folderPath}'`);
      return undefined;
    });
  }

  /**
   * Deletes a file at the specified path.
   * @param filePath The path of the file to delete.
   * @returns A promise that resolves to true if the file was deleted successfully, or undefined if an error occurs.
   */
  async removeFileFromFilesystem(filePath: string): Promise<boolean | undefined> {
    return Filesystem.deleteFile({
      path: filePath,
      directory: Directory.Data
    }).then(() => true).catch(error => {
      this.handleStorageError(this.removeFileFromFilesystem.name, error);
      return undefined;
    });
  }

  /**
   * Initializes the storage.
   */
  private async init(): Promise<void> {
    try {
      // create a storage instance
      const storage = await this.storage.create();
      this._storage = storage;      
    } catch (error) {
      this.handleStorageError(this.init.name, error);
    }
  }

  /**
   * Handles storage-related errors.
   * @param caller The method where the error occured.
   * @param error The error that occurred.
   */
  private handleStorageError(caller: string, error: any, message?: string) {
    const errorlog: IAppErrorLog = {
      className: this.constructor.name,
      methodName: caller,
      error,
      timestamp: new Date(),
      message
    }

    logError(errorlog);
  }
}
