<ion-accordion-group>
  <ion-accordion *ngFor="let asset of jobAssets" [value]="asset.jobAssetGuid" toggleIcon="chevron-down-outline"
    toggleIconSlot="start">
    <ion-item slot="header" color="light">
      <ion-grid class="ion-no-padding ion-padding-vertical">
        <ion-row class="ion-align-items-center">
          <ion-col size="auto">
            <ion-label>{{ asset.description }}</ion-label>
          </ion-col>
          <ion-col>
            <lib-status-counter class="ion-float-right" [counter]="fillAssetCounter(asset)"></lib-status-counter>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-item>
    <div class="ion-padding-vertical ion-padding-start" slot="content">

      <lib-asset-media-set [jobGuid]="job.jobGuid" [jobAsset]="asset" [job]="job">
      </lib-asset-media-set>

    </div>
  </ion-accordion>
</ion-accordion-group>