import { Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { DynamicEnvironmentService } from './dynamic-environment.service';
import { StatusRetryPolicy } from './retry.policy';

@Injectable({
    providedIn: 'root',
})
export class SignalRService {
    private hubConnection: HubConnection;
    private connected = false;
    private readonly retryIntervals = [0, 1000, 2000, 3000, 4000, 5000, 10000, 15000, 20000, 25000, 30000];

    constructor(private envService: DynamicEnvironmentService) {
        this.initializeConnection();
    }

    private initializeConnection() {
        this.hubConnection = new HubConnectionBuilder()
            .withUrl(this.envService.environment.SignalR)
            .withAutomaticReconnect(new StatusRetryPolicy())
            .build();

        this.startConnectionWithRetry();
    }

    private async startConnectionWithRetry(retryCount = 0): Promise<void> {
        try {
            console.log(`Attempting to connect to SignalR Hub. Retry #${retryCount}`);
            await this.hubConnection
                .start();

            this.connected = true;
        } catch (error) {
            console.error('SignalR connection failed:', error);
            this.connected = false;

            // Retry indefinitely
            const retryDelay = this.retryIntervals[Math.min(retryCount, this.retryIntervals.length - 1)];
            console.log(`Retrying in ${retryDelay / 1000}s...`);
            await this.delay(retryDelay);
            await this.startConnectionWithRetry(retryCount + 1); // Recursive retry
        }
    }

    private delay(ms: number): Promise<void> {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    get connection(): HubConnection {
        return this.hubConnection;
    }

    isConnected(): boolean {
        return this.connected;
    }
}
