import { AvailableEnvironment } from "./available-environments";

export const environment = {
  Production: false,
  BuildEnvironment: AvailableEnvironment.Dev,
  PortalUrl: 'https://portal.paplus.dev.rgsit.cloud',
  MobileUrl: 'https://mobile.paplus.dev.rgsit.cloud',
  Api: 'https://api.paplus.dev.rgsit.cloud/odata',
  SignalR: 'https://api.paplus.dev.rgsit.cloud/signalr',
  B2cDomain: 'paplusdev',
  B2cTenantId: '31806fdc-be9d-4e64-88df-f9579aa99fbd',
  PortalClientId: '0e013de8-0fad-4b64-91ce-97f53328d206',
  MobileClientId: 'a236752e-fa6b-441a-89bf-0f3e7a7411b0',
  BackendAppIdentifier: 'dev-api',
  GoogleMapsApiKey: 'AIzaSyAh-wSELQf54o5x6p33MDkucbMC2L0oHdQ'
};
