import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { DownloadingOverlayComponent } from '@sharedLibrary/dialogs/downloading-overlay/downloading-overlay.component';
import { ModalController } from '@ionic/angular';
import { ProgressOverlayConfig } from '../models/download-manager-interfaces';
import { IAppErrorLog } from '@sharedLibrary/models/errors';
import { logError } from '@sharedLibrary/utils/error-logger';

@Injectable({
  providedIn: 'root'
})
export class DownloadManagerService {
  
  private downloadProgressSubject = new BehaviorSubject<number>(0);
  downloadProgress$ = this.downloadProgressSubject.asObservable();

  private downloadSuccessfulSubject = new Subject<boolean>();
  downloadSuccessful$ = this.downloadSuccessfulSubject.asObservable();

  private showDownloadOverlaySubject = new Subject<boolean>();

  private totalItems: number = 0;
  private downloadedCount = 0;
  private isOverlayVisible: boolean = false;
  private overlayInstance: HTMLIonModalElement | null = null;
  private defaultConfig: ProgressOverlayConfig = {
    message: 'Please wait...',
    showPercentage: false,
    progressBarType: 'indeterminate'
  };


  constructor(
    private modalCtrl: ModalController
  ) {
    this.showDownloadOverlaySubject.subscribe(
      {
        next: (showOverlay)=> {          
          if (this.isOverlayVisible) {;
            return;
          }
          
          if (showOverlay) {
            this.isOverlayVisible = true;
            this.showProgressOverlay();            
          } else {
            this.hideProgressOverlay()
          }
        },
        error: (error: Error) => {
          this.handleError(this.constructor.name, error, 'this.showDownloadOverlaySubject subscription');
        }
      }
    );
  }

  singleItemProcessed(success: boolean) {

    if (!success) {
      this.downloadSuccessfulSubject.next(false);
    } else {
      this.downloadSuccessfulSubject.next(true);      
    }
    
    this.downloadedCount++;
    this.updateProgress();
  }

  // this adds the number of items that need downloading to the total number
  addItemsQuantityToDownload(quantity: number) {
    this.totalItems =+ quantity;
  }

  async startDownloadProcess(overlayConfig: ProgressOverlayConfig = {}) {
    // overwrite default configuration
    this.defaultConfig = { ...this.defaultConfig, ...overlayConfig };

    // increas total items to download count
    this.totalItems ++;

    this.showDownloadOverlaySubject.next(true);
  }

  async hideProgressOverlay() {
    this.showDownloadOverlaySubject.next(false);
    if (this.overlayInstance) {
      await this.overlayInstance.dismiss();
    }
  }
  
  private async showProgressOverlay(overlayConfig: ProgressOverlayConfig = {}) {    
    this.overlayInstance = await this.modalCtrl.create({
      component: DownloadingOverlayComponent,
      componentProps: {
        message: this.defaultConfig.message,
        showPercentage: this.defaultConfig.showPercentage,
        progressBarType: this.defaultConfig.progressBarType
      },
      cssClass: 'downloading-overlay',
      backdropDismiss: false
    });

    this.overlayInstance.onWillDismiss()
    .then(() => {
      this.resetProgress();
      this.isOverlayVisible = false;
    });
    await this.overlayInstance.present();
  }
  
  // reset all the value when the download overlay has been dismissed
  resetProgress() {
    this.downloadedCount = 0;
    this.totalItems = 0;
    this.downloadProgressSubject.next(0);
  }

  private async updateProgress() {
    const progress = (this.downloadedCount / this.totalItems);
    
    this.downloadProgressSubject.next(progress);
    const downloadFinished = progress == 1
    if (downloadFinished) {
      await new Promise(resolve => setTimeout(resolve, 1000));
      this.hideProgressOverlay();
    }
  }

  private handleError(caller: string, error: any, message?: string) {
    const errorlog: IAppErrorLog = {
      className: this.constructor.name,
      methodName: caller,
      error,
      timestamp: new Date(),
      message
    }

    logError(errorlog);
  }
}
