import { Injectable } from "@angular/core";
import { Geolocation, Position } from '@capacitor/geolocation';
import { StorageKey } from "@sharedLibrary/enums/storage-enums";
import { BehaviorSubject } from "rxjs";
import { StorageService } from "./storage.service";


@Injectable({
  providedIn: 'root',
})
export class LocationService {

  private currentPositionSubject = new BehaviorSubject<Position>(null);


  constructor(
    private localStorageService: StorageService
  ) {
    this.getCurrentUserLocation();
  }
  
  async isLocationServiceOn(): Promise<boolean> {
    try {
      const permissions = await Geolocation.checkPermissions();
      console.log("Permissions:", permissions)
      if (permissions.coarseLocation === 'granted') {
        return true;        
      }
      else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }

  currentPosition$() {
    return this.currentPositionSubject.asObservable();
  }
  
  async getCurrentUserLocation() {
    try {
        // Directly await the call to getCurrentPosition
        const dataLocation = await Geolocation.getCurrentPosition();
        if (dataLocation) {
            // Format the geolocation data
            const geoLocationFormatted = this.formatGeoObject(dataLocation);

            // Update the current position subject
            this.currentPositionSubject.next(geoLocationFormatted);

            // Save the location to local storage
            await this.localStorageService.setStorageKey(StorageKey.MyLocations, geoLocationFormatted);

            // Return the formatted geolocation data
            return geoLocationFormatted;
        }
        return null; // Return null if no dataLocation is found
    } catch (error) {
        console.log('Error getting user location:', error);
        //TODO: add logging mechanism
        return null; // Return null in case of an error
    }
  }

  isLocationValid(location: Position) {
    return (!isNaN(location?.timestamp) && !isNaN(location?.coords.latitude) && !isNaN(location?.coords.longitude));
  }

  // the capacitor geo object cannot be stored directly in the IndexedDB, it needs to be formatted
  private formatGeoObject(location: Position): Position {
    return {
      timestamp: location.timestamp,
      coords: {
        accuracy: location.coords.accuracy,
        altitude: location.coords.altitude,
        altitudeAccuracy: location.coords.altitudeAccuracy,
        heading: location.coords.heading,
        latitude: location.coords.latitude,
        longitude: location.coords.longitude,
        speed: location.coords.speed
      }
    }
  }


}

