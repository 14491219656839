import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JobStatusPipe } from './pipes/job-status.pipe';
import { JobAssetsContainerComponent } from './components/job-assets-container/job-assets-container.component';
import { IonicModule } from '@ionic/angular';
import { AssetMediaSetComponent } from './components/asset-media-set/asset-media-set.component';
import { StatusCounterComponent } from './components/status-counter/status-counter.component';
import { CaptureCardComponent } from './components/capture-card/capture-card.component';
import { UploadCaptureComponent } from './components/upload-capture/upload-capture.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { TakeCaptureComponent } from './components/take-capture/take-capture.component';
import { AssetCounterService } from './services/asset-counter.service';
import { ViewCaptureComponent } from './components/view-capture/view-capture.component';
import { DownloadingOverlayComponent } from './dialogs/downloading-overlay/downloading-overlay.component';
import { AuthRedirectWrapperComponent } from './components/auth/auth-redirect-wrapper.component';
import { MsalModule } from '@azure/msal-angular';


@NgModule({
  declarations: [
    // Declare components
    AssetMediaSetComponent,
    AuthRedirectWrapperComponent,
    CaptureCardComponent,
    DownloadingOverlayComponent,
    JobAssetsContainerComponent,
    StatusCounterComponent,
    UploadCaptureComponent,
    TakeCaptureComponent,
    ViewCaptureComponent,
    // Declare pipes
    JobStatusPipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    MatIconModule,  // Do we need these for the camera/photo upload stuff @Paul Brandreth
    MatFormFieldModule, // Do we need these for the camera/photo upload stuff @Paul Brandreth
    MsalModule,
    ReactiveFormsModule,
    TranslateModule
  ],
  exports: [
    // Export modules
    CommonModule,
    FormsModule,
    IonicModule,
    MatIconModule,
    MatFormFieldModule,
    MsalModule,
    ReactiveFormsModule,
    TranslateModule,
    // Export components
    AssetMediaSetComponent,
    AuthRedirectWrapperComponent,
    CaptureCardComponent,
    JobAssetsContainerComponent,
    StatusCounterComponent,
    UploadCaptureComponent,
    TakeCaptureComponent,
    ViewCaptureComponent,
    // Export pipes
    JobStatusPipe
  ],
  providers: [
    AssetCounterService
  ]
})
export class SharedLibraryModule { }
