import { RetryContext, IRetryPolicy } from '@microsoft/signalr';

export class StatusRetryPolicy implements IRetryPolicy {
    private readonly retryDelays = [
        0, // Immediate
        1000, // 1 second
        2000, // 2 seconds
        3000, // 3 seconds
        4000, // 4 seconds
        5000, // 5 seconds
        10000, // 10 seconds
        15000, // 15 seconds
        20000, // 20 seconds
        25000, // 25 seconds
        30000, // 30 seconds
    ];

    public nextRetryDelayInMilliseconds(retryContext: RetryContext): number | null {
        const { previousRetryCount } = retryContext;

        if (previousRetryCount < this.retryDelays.length) {
            // Use the delay from the retryDelays array
            return this.retryDelays[previousRetryCount];
        }

        // After exhausting the retryDelays array, retry every 30 seconds
        return 30000;
    }
}
