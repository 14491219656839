import { Injectable } from "@angular/core";
import { CaptureEdm, JobAssetEdm, MediaStatusEdm, PersonMediaStatusEdm, JobAssetMediaEdm } from "@odata";
import { MediaStatus } from "@sharedLibrary/enums/status-enums";
import { getActiveItemLatestFromUtcDateTime } from "@sharedLibrary/utils/array-utils";
import { map, Observable } from "rxjs";

Injectable({
    providedIn: 'root'
})
export class AssetCounterService {

    private pendingUploadStatus: number = -1;
    private MediaStatuses: MediaStatusEdm[] = [];

    constructor() { }

    newMediaStatusCount(): MediaStatusCount {
        let statusCounter: MediaStatusCount = {
            uploaded: 0,
            starred: 0,
            approved: 0,
            rejected: 0,
            requestedRetake: 0,
            awaiting: 0,
            pendingUpload: 0
        };
        return statusCounter;
    }

    getMediaStatusArray(): MediaStatusEdm[] {
        if (this.MediaStatuses.length == 0) {
            // this.getMediaStatuses().subscribe(res => {
            //     this.MediaStatuses = res;
            //     return this.MediaStatuses;
            // });
        }

        return this.MediaStatuses;

    }

    // getMediaStatuses(): Observable<MediaStatusEdm[]> {
    //     let statusService = this.factory.entitySet<MediaStatusEdm>(
    //       "MediaStatuses",
    //       "paplusApi.DataDomain.EFEntities.MediaStatusEdm"
    //     );
    
    //     let statuses = statusService.entities();
    //     return statuses.fetch().pipe(
    //       map(res => res.entities == null ? [] : res.entities)
    //     )
    //   }

    fillAssetCounter(gAsset: JobAssetEdm): MediaStatusCount {
        let allCaptures: CaptureEdm[] = [];
        let allStatuses: MediaStatusEdm[] = [];

        this.accountMediaStatusesForHierarchy(gAsset, allCaptures, allStatuses);

        return this.summarizeMediaStatuses(allCaptures, allStatuses);
    }

    fillJobCounter(jobAssets: JobAssetEdm[]): MediaStatusCount {
        let allCaptures: CaptureEdm[] = [];
        let allStatuses: MediaStatusEdm[] = [];

        jobAssets.forEach(j => {
            this.accountMediaStatusesForHierarchy(j, allCaptures, allStatuses);
        });

        return this.summarizeMediaStatuses(allCaptures, allStatuses);
    }

    summarizeMediaStatuses(allCaptures: CaptureEdm[], allStatuses: MediaStatusEdm[]) {

        let statusCounter = this.newMediaStatusCount();
        try {

            if (allStatuses && allStatuses.length > 0) {
                statusCounter.uploaded = allCaptures.length;
                statusCounter.starred = 0;
                statusCounter.approved = allStatuses.filter(s => s.enumVal == MediaStatus.Approved).length;
                statusCounter.rejected = allStatuses.filter(s => s.enumVal == MediaStatus.Rejected).length;
                statusCounter.requestedRetake = allStatuses.filter(s => s.enumVal == MediaStatus.Retake).length;
                statusCounter.awaiting = allStatuses.filter(s => s.enumVal == MediaStatus.Awaiting).length;
                statusCounter.pendingUpload = allStatuses.filter(s => s.enumVal == this.pendingUploadStatus).length;
            }
        }
        catch (error) {
            debugger;
            console.log(error);
        }

        return statusCounter;
    }

    accountMediaStatusesForHierarchy(gAsset: JobAssetEdm, allCaptures: CaptureEdm[], allStatuses: MediaStatusEdm[]) {

        // including the root level medias to account in the statistics
        this.accountMediaStatuses(gAsset.jobAssetMedias, allCaptures, allStatuses);

        if (gAsset.childAssets && gAsset.childAssets.length > 0) {
            gAsset.childAssets.forEach(a => {

                // recursivelly going through all the inner subitems
                this.accountMediaStatusesForHierarchy(a, allCaptures, allStatuses);
            });
        }
    }

    accountMediaStatuses(jobAssetMedias: JobAssetMediaEdm[], allCaptures: CaptureEdm[], allStatuses: MediaStatusEdm[]) {
        jobAssetMedias.forEach(m2 => {
            allCaptures = allCaptures.concat(m2.captures);

            if (!m2.personMediaStatuses || m2.personMediaStatuses.length === 0) {
                allStatuses.push({
                    enumVal: this.pendingUploadStatus // the item is pending upload. The media has not been uploaded yet.
                } as MediaStatusEdm);
            } else {

                let latestStatusItem: PersonMediaStatusEdm = getActiveItemLatestFromUtcDateTime((m2.personMediaStatuses as PersonMediaStatusEdm[]));
                // an asset media item should have only one, latest and active status.
                if (latestStatusItem) {
                    allStatuses.push(latestStatusItem.mediaStatus);
                }

                /*m2.personMediaStatuses.forEach(pms2 => {
     
                    if (pms2.toUtcDateTime == null) {
                        allStatuses.push(pms2.mediaStatus);
                    }
                });*/
            }

        });
    }
}

export interface MediaStatusCount {
    uploaded: number;
    starred: number;
    approved: number;
    rejected: number;
    requestedRetake: number;
    awaiting: number;
    pendingUpload: number;
}