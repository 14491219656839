import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Platform } from "@ionic/angular";
import { IAppErrorLog } from "@sharedLibrary/models/errors";
import { logError } from "@sharedLibrary/utils/error-logger";


@Injectable({
  providedIn: 'root'
})
export class CommonService {
  constructor(
    private platform: Platform,
    private snackBar: MatSnackBar
  ) {

  }

  createArrayFromEnum(e: any) {
    return Array.from(
      Object.keys(e).filter(k => isNaN(Number(k))),
      r => {
        return { text: r, value: e[r] };
      }
    );
  }

  disableDropDownIfOneItem(arr: any[]): boolean {
    if (arr.length == 1) {
      return true;
    }
    else {
      return false;
    }
  }

  handleError(caller: string, error: any, message?: string) {
    const errorlog: IAppErrorLog = {
      className: this.constructor.name,
      methodName: caller,
      error,
      timestamp: new Date(),
      message
    }

    logError(errorlog);
  }

  openSnackBar(message: string, length: number, styleClass: string) {
    this.snackBar.open(message, '', { duration: length, panelClass: [styleClass] });
  }

  selectSingleItem(arr: any[]): any {
    if (arr.length == 1) {
      return arr[0];
    }
    else {
      return null;
    }
  }

  isMobile(): boolean {
    // Check if the device is a mobile platform
    console.log('Platform: ', this.platform.platforms())
    return this.platform.is('mobile') || this.platform.is('mobileweb');
  }

}