import { AvailableEnvironment } from "./available-environments";

export const environment = {
  Production: false,
  BuildEnvironment: AvailableEnvironment.Training,
  PortalUrl: 'https://portal.paplus.training.rgsit.cloud',
  MobileUrl: 'https://mobile.paplus.training.rgsit.cloud',
  Api: 'https://api.paplus.training.rgsit.cloud/odata',
  SignalR: 'https://api.paplus.training.rgsit.cloud/signalr',
  B2cDomain: 'papluspreprod',
  B2cTenantId: '2e24d2f8-d536-4570-97a9-b504694e85a1', // TODO
  PortalClientId: '21589c07-0c6a-4965-980c-ca57b06d1eb6', // TODO
  MobileClientId: 'a236752e-fa6b-441a-89bf-0f3e7a7411b0', // TODO
  BackendAppIdentifier: 'training-api',
  GoogleMapsApiKey: 'AIzaSyAh-wSELQf54o5x6p33MDkucbMC2L0oHdQ'
};
