import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MediaStatusEdm } from '@odata';
import { PersonEdm } from '@odata';
import { PersonMediaStatusEdm } from '@odata';
import { CapturesService } from '@sharedLibrary/services/captures.service';
import { JobAssetMediaService, PersonMediaStatusService } from '@sharedLibrary/services/job-asset-media.service';
import { MediaStatus } from '@sharedLibrary/enums/media-status-enum';
import { PAPlusUserAccountService } from '@sharedLibrary/services/paplus-account.service';
import { MsalService } from '@azure/msal-angular';
import { ModalController, Platform } from '@ionic/angular';
import { CaptureSource } from '@sharedLibrary/enums/capture-enums';

@Component({
  selector: 'app-upload-capture',
  templateUrl: './upload-capture.component.html',
  styleUrls: ['./upload-capture.component.scss'],
  providers: [PersonMediaStatusService]
})
export class UploadCaptureComponent implements OnInit {

  @Input() jobGuid: string;
  @Input() jobAssetGuid: string;
  @Input() jobAssetMediaGuid: string;

  public hasError = false;
  public buttonDisabled = false;
  private mediaStatuses: MediaStatusEdm[] = [];
  private loggedInUser: PersonEdm;

  //Document Params
  public documentField: FormControl = new FormControl('', Validators.required);
  public selectedFileName: string = '';
  private selectedFileToUpload: File | null = null;
  public readonly selectAFileInvitation: string = 'Please choose or drop a file.';
  public fileHasBeenDroppedIn: boolean = false;

  @ViewChild('documentToUpload') documentToUpload!: ElementRef;
  @ViewChild('documentDragZoneControl') documentDragZoneControl!: ElementRef<HTMLDivElement>;

  constructor(
    private authService: MsalService,
    private capturesService: CapturesService,
    private jobAssetMediaService: JobAssetMediaService,
    private personMediaStatusService: PersonMediaStatusService,
    private modalController: ModalController,
    private paplusUserService: PAPlusUserAccountService,
    private platform: Platform
  ) { }

  ngOnInit() {
    let activeAccount = this.authService.instance.getActiveAccount();
    if (activeAccount !== null) {
      this.paplusUserService.retrievePaPlusUser(activeAccount.localAccountId).subscribe(res => {
        this.loggedInUser = res;
      });
    }

    this.jobAssetMediaService.getMediaStatuses().subscribe(statuses => {
      this.mediaStatuses = statuses;
    });
  }

  uploadClick() {
    if (this.documentField.valid || this.fileHasBeenDroppedIn) {
      this.hasError = false;
      this.buttonDisabled = true;
      this.uploadDocument();
    }
  }

  async uploadDocument() {

    let fileToUpload: File = this.selectedFileToUpload as File;
    if (fileToUpload) {

      let personMediaStatus: PersonMediaStatusEdm = {
        personGuid: this.loggedInUser.personGuid,
        jobAssetMediaGuid: this.jobAssetMediaGuid,
        mediaStatusGuid: this.mediaStatuses.find(s => s.enumVal == MediaStatus.Awaiting).mediaStatusGuid,
        fromUtcDateTime: new Date(),
        notes: 'Initial Upload'
      }

      this.personMediaStatusService.createPersonMediaStatus(personMediaStatus).subscribe({
        next: (pms => {
          this.capturesService.uploadCapture(
            this.jobGuid,
            this.jobAssetGuid,
            this.jobAssetMediaGuid,
            this.getCaptureSource(),
            undefined,
            undefined,
            undefined,
            fileToUpload).subscribe({
              next: (res => {
                this.modalController.dismiss();
              }),
              error: (error => {
                this.buttonDisabled = false;
                this.hasError = true;
              })
            });
        }),
        error: (error => {
          this.buttonDisabled = false;
          this.hasError = true;
        })
      });
    }
  }

  cancelClick() {
    this.modalController.dismiss({ cancel: "direct" });
  }


  //File Upload Functions
  onFileSelected(event: Event) {
    if (event && event.target) {
      if (((<HTMLInputElement>event.target).files as FileList).length === 0) {
        this.selectedFileName = '';
        this.selectedFileToUpload = null;
        this.fileHasBeenDroppedIn = false;
      } else {
        this.selectedFileToUpload = ((<HTMLInputElement>event.target).files as FileList)[0];
        this.selectedFileName = this.selectedFileToUpload.name;
      }
    }
  }

  allowDrop(ev: any) {
    ev.preventDefault();
  }

  somethingIsDraggedOver(event: any) {
    this.documentDragZoneControl.nativeElement.classList.add('file-dragged-over');
  }

  draggedOut(event: any) {
    this.documentDragZoneControl.nativeElement.classList.remove('file-dragged-over');
  }

  handleFileDrop(event: any) {
    event.stopPropagation();
    event.preventDefault();

    this.documentDragZoneControl.nativeElement.classList.remove('file-dragged-over');

    var files = (event as DragEvent).dataTransfer?.files;

    if (files && files.length > 0) {
      this.selectedFileToUpload = files[0];
      this.selectedFileName = this.selectedFileToUpload.name;
    }

    this.fileHasBeenDroppedIn = true;
  }

  selectFileDialog() {
    this.documentToUpload.nativeElement.click();
  }

  getCaptureSource(): CaptureSource {
    if (this.platform.is('ios') || this.platform.is('android') || this.platform.is('mobile')) {
      return CaptureSource.AppUpload;
    }
    else {
      return CaptureSource.Portal;
    }
  }
}
