import { EntityKey, ODataServiceFactory } from "angular-odata";
import { BaseODataService, ODataServiceConfig } from "./base-odata.service";
import { BaseSignalRService, SignalREventConfig, SignalREventType } from "./base-signalr.service";
import { SignalRService } from "./signalr.service";

export interface ODataServiceSignalRConfig extends ODataServiceConfig {
    events: SignalREventConfig | SignalREventConfig[];
}

export abstract class BaseODataSignalRService<T> extends BaseODataService<T> {
    protected baseSignalRService: BaseSignalRService<T>;

    constructor(
        factory: ODataServiceFactory,
        protected signalRService: SignalRService,
        protected config: ODataServiceSignalRConfig
    ) {
        super(factory, config);
        this.initializeSignalR();
    }

    override destroy() {
        this.baseSignalRService.destroy();
        super.destroy();
    }

    protected initializeSignalR(): void {
        this.baseSignalRService = new BaseSignalRService<T>(this.signalRService, this.config.events);
        this.baseSignalRService.events$.subscribe(event => {
            if (event.event & SignalREventType.Created) {
                this.handleCreated(event.item, event.name);
            }
            if (event.event & SignalREventType.Updated) {
                this.handleUpdated(event.item, event.name);
            }
            if (event.event & SignalREventType.Deleted) {
                this.handleDeleted(event.itemKey, event.name);
            }
        });
    }

    protected handleCreated(item: T, name: string): void {
        console.log(`Handle Created ${name}`, item);

        // Update the seach results
        this.refreshSearch();
    }

    protected handleUpdated(item: T, name: string): void {
        console.log(`Handle Updated ${name}`, item);

        // Update single item if it matches the updated item
        const existingItemKey = JSON.stringify(this.currentItemKey);
        if (this.keysMatch(this.currentItemKey, this.getItemKey(item))) {
            this.refreshGet();
        }

        // Update the seach results
        this.refreshSearch();
    }

    protected handleDeleted(itemKey: EntityKey<T>, name: string): void {
        console.log(`Handle Deleted ${name}`, itemKey);

        // Update the seach results
        this.refreshSearch();
    }

    protected keysMatch(key1: EntityKey<T>, key2: EntityKey<T>): boolean {
        return JSON.stringify(key1) === JSON.stringify(key2);
    }

    protected abstract getItemKey(item: T): EntityKey<T>;
}