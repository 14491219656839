<ion-content>

  <div class="main-progress-container">
    <ion-item class="header-text" lines="none">
      <ion-label text-wrap>{{ message }}</ion-label>
    </ion-item>
    
    <ion-progress-bar [type]="progressBarType" [value]="progress"></ion-progress-bar>

    <ion-item-group>
      <ion-item *ngIf="showPercentage" lines="none">
        <ion-badge *ngIf="showFailedDownloads" color="danger">{{ failedDownloads }} failed</ion-badge>
        <ion-label class="percentage-text"> {{ percentage }}%</ion-label>
      </ion-item>  
    </ion-item-group>
  </div>

</ion-content>