import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-login-header',
  templateUrl: './login-header.component.html',
  styleUrls: ['./login-header.component.scss'],
})
export class LoginHeaderComponent  implements OnInit {

  constructor(private authService: MsalService,) { }

  ngOnInit() {}


  logout() {
    let logoutUseractionParam: { [key: string]: string } = {
      'useraction': 'true'
    };
    this.authService.logout({
      postLogoutRedirectUri: '/logout?useraction=true',
      extraQueryParameters: logoutUseractionParam
    });
  }
}
