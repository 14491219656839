<ion-header>

  <ion-toolbar>
    <ion-label slot="end">#{{ captureCount }}</ion-label>
    <ion-buttons slot="end">
      <ion-button class="toolbar-button" (click)="cancelClick()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>Capture Image</ion-title>
  </ion-toolbar>
</ion-header>


<ion-content>
  <ion-item-group>
    <ion-item lines="none">
      <ion-label>
        <h2 class="header-text">{{ jobAssetMedia.description }}</h2>
      </ion-label>
    </ion-item>
    <ion-item lines="none">
      <ion-label>
        <ng-container *ngIf="jobAssetMedia.comment; else noComments">
          <p>{{ jobAssetMedia.comment }}</p>
        </ng-container>
        <ng-template #noComments>
          <p>Photo has no additional comments.</p>
        </ng-template>
      </ion-label>
    </ion-item>
    <ion-img [src]="image"></ion-img>
    <ion-button (click)="uploadClick()" color="secondary" slot="end" class="modal-confirm">
      Upload
    </ion-button>
    <ion-button (click)="openCamera()" class="upload-media-btn" color="secondary">Retake</ion-button>
    <!-- <ng-container *ngIf="!photoElement.isComplete; else photoComplete">
  
        <ion-item lines="none">
          <ion-button #openCameraButton (click)="openCamera()">
            <ion-icon slot="end" name="camera-outline"></ion-icon>
            {{ takePhotoText }}
          </ion-button>
          <ion-button [disabled]="!canUpload" (click)="upload()">
            <ion-icon slot="end" name="cloud-upload-outline"></ion-icon>
            Accept
          </ion-button>
        </ion-item>
  
        <ion-item>
          <ion-button #completeSeriesButton *ngIf="photoElement.isSeries" (click)="completeSeries()">
            <ion-icon slot="end" name="checkmark-circle-outline"></ion-icon>
            Complete Series
          </ion-button>
          <ion-button [disabled]="!canUpload || captureNote !== null" (click)="presentAddNoteModal()">
            <ion-icon slot="end" name="chatbox-outline"></ion-icon>
            Add Note
          </ion-button>
        </ion-item>
  
      </ng-container>
  
      <ng-template #photoComplete>
        <p class="photo-completed-text">Photo has been completed{{ photoElement.photoCapturesCount === null ? ' with no captures.' : '.'}}</p>
      </ng-template> -->
  </ion-item-group>

  <ion-item-group>

    <ion-list>
      <ion-item>
        <ion-label>
          <h2 class="header-text">Capture Notes</h2>
        </ion-label>
      </ion-item>

      <ion-item lines="none" *ngIf="jobAssetMedia.personMediaStatuses">
        <ng-container *ngIf="jobAssetMedia.personMediaStatuses.length > 0; else noNotes">
        <ng-container *ngFor="let captureNote of jobAssetMedia.personMediaStatuses">
          <ion-label text-wrap>
            <p>{{ captureNote.notes }}</p>
          </ion-label>
          <ion-button (click)="deleteNote()">
            <ion-icon slot="icon-only" name="close"></ion-icon>
          </ion-button>
        </ng-container>
          
        </ng-container>
        <ng-template #noNotes>
          <ion-label text-wrap>
            <p>You have not added a note yet.</p>
          </ion-label>
        </ng-template>

      </ion-item>
    </ion-list>
  </ion-item-group>

</ion-content>