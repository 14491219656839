export function getItemLatestFromUtcDateTime<T extends IDatesRange>(list: T[]) {
    if(!list) {
        return null;
    }

    if(list.length === 1) {
        return list[0];
    }

    list.sort((a, b) => new Date(b.fromUtcDateTime).getTime() - new Date(a.fromUtcDateTime).getTime());

    return list[0];
};

export function getActiveItemLatestFromUtcDateTime<T extends IDatesRange>(list: T[]) {
    if(!list) {
        return null;
    }

    if(list.length === 1) {
        return list[0];
    }

    let activeListItems = list.filter((rn) => !rn.toUtcDateTime);

    if(activeListItems.length === 0) {
        return null;
    }

    return getItemLatestFromUtcDateTime(activeListItems);
};

export interface IDatesRange {
    fromUtcDateTime: Date
    toUtcDateTime?: Date
}