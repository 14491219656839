/**
 * Enums related to the status of a job
 */
export enum JobStatus {
    Draft, //the job record has been created but is not completed, and it is not ready to be assigned to a site worker
    Created, //the job has been created and is ready for assignment 
    Assigned, //the job has been assigned to a site worker(or site workers)
    Started,//at least one piece of media has been submitted for the job
    Submitted, //all the media required for the job has been submitted, but not approved
    Approved, //all the media has been approved by the reviewer(s)
    Closed, //the close out package has been sent
    Archived, //the job is no longer required to be displayed on job lists by default 
}

/**
 * Enums related to the status of a media within the media set
 */
export enum MediaStatus {
    Awaiting,
    Retake,
    Rejected,
    Approved
}