import { Component, Input, OnInit } from "@angular/core";
import { JobAssetEdm, JobAssetMediaEdm, JobEdm } from "@sharedLibrary/odata";
import { AssetCounterService } from "@sharedLibrary/services/asset-counter.service";


@Component({
  selector: 'lib-asset-media-set',
  templateUrl: './asset-media-set.component.html',
  styleUrls: ['./asset-media-set.component.scss'],
})
export class AssetMediaSetComponent implements OnInit {

  @Input() job: JobEdm;
  @Input() jobGuid: string;
  @Input() jobAsset: JobAssetEdm;
  
  public childAssets: JobAssetEdm[] = [];
  public mediaList: JobAssetMediaEdm[];

  constructor(
    private assetCounterService: AssetCounterService
  ) { }

  ngOnInit() {

    if (this.jobAsset) {
      this.childAssets = this.jobAsset.childAssets;
      this.mediaList = this.jobAsset.jobAssetMedias;
    }
  }

  fillAssetCounter(asset: JobAssetEdm) {
    return this.assetCounterService.fillAssetCounter(asset);
  }
}
